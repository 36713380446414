import { NgModule } from '@angular/core';
import {BeagleErrorScreenService} from './error-screen.service';

@NgModule({
    providers: [
        BeagleErrorScreenService
    ]
})
export class BeagleErrorScreenModule
{
    /**
     * Constructor
     *
     * @param {BeagleErrorScreenService} _beagleErrorScreenService
     */
    constructor(
        private _beagleErrorScreenService: BeagleErrorScreenService
    )
    {
    }
}
