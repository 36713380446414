import {ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {LoadingService} from '../loading/loading.service';
import {Router} from '@angular/router';
import {Order} from '../../../modules/order/order.types';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {OrderService} from '../../../modules/order/order.service';
import {BeagleErrorScreenService} from '../../../../@beagle/services/error-screen';

@Component({
    selector: 'float-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [BarcodeScanner]
})
export class MenuComponent implements OnInit {

    config: any;
    salesman: any;
    design: any;
    orderPopUp: boolean;

    /**
     * Constructor
     *
     * @param _loadingService
     * @param _router
     * @param _barcodeScanner
     * @param _changeDetection
     * @param _orderService
     * @param _errorService
     */
    constructor(

        private _loadingService: LoadingService,
        private _router: Router,
        private _barcodeScanner: BarcodeScanner,
        private _changeDetection: ChangeDetectorRef,
        private _orderService: OrderService,
        private _errorService: BeagleErrorScreenService

    ) {

        this.config = JSON.parse(localStorage.getItem('cache:config'));

        this.salesman = JSON.parse(localStorage.getItem('cache:salesman'));
        this.design = JSON.parse(localStorage.getItem('cache:design'));

        this.orderPopUp = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit() {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * New sale
     */
    new(): void
    {
        const route = this._router.url.split('/')[1];

        let redirectUrl = '/category';

        if (this.config.client_register && route !== 'order') {
            redirectUrl = '/ticket';
        }

        if (this.config.all_products === 1) {
            redirectUrl = '/product?c=0&n=Todos';
        }

        this._loadingService.show('');
        this._router.navigateByUrl(redirectUrl)
            .then(() => this._loadingService.hide());
    }

    /**
     * Find order by qrcode
     */
    ticket(): void
    {
        this._barcodeScanner.scan({
            prompt : "Posicione o código no campo indicado",
            resultDisplayDuration: 0
        })
            .then((result) => {

                if(result.text !== ''){
                    // Show loading
                    this._loadingService.show();

                    // Mark for check
                    this._changeDetection.markForCheck();

                    // Get ticket
                    this._orderService.getOrderByQrcode(result.text)
                        .subscribe((response: Order) => {

                            if (response) {
                                // Redirect client
                                this._router.navigate(['/order', response.code])
                                    .then(() => this._loadingService.hide());
                            } else {
                                // Show loading
                                this._loadingService.hide();

                                // Show popup
                                this.orderPopUp = true;

                                // Mark for check
                                this._changeDetection.markForCheck();
                            }
                        }, (responseError) => {
                            throw new Error(responseError?.error?.message ??  'Erro ao buscar comanda usando scan QRCode');
                        });
                }
            }, (error) => {
                this._errorService.show(error);
                throw new Error(error?.error?.message + 'Erro ao tentar usar o scan QRCode');
            });
    }

}
