import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {AuthUtils} from './auth.utils';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthService {

    
    // Private
    private _authenticated: boolean;
    private _isNetworkError: BehaviorSubject<boolean | null>;
    
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) {
        // Set the defaults
        this._authenticated = false;
        this._isNetworkError = new BehaviorSubject(null);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('cache:token', token);
    }

    get accessToken(): string {
        return localStorage.getItem('cache:token');
    }

    /**
     * Get for network error
     */
    get isNetworkError$(): Observable<any>
    {
        return this._isNetworkError.asObservable();
    }

    toggleNetworkError(value: boolean): void {
        // Store the value
        this._isNetworkError.next(value);
    }

    
  

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get config in local db
     *
     * @param credential
     */
    identityIn(credential: string): Observable<any>
    {
        return this._httpClient.get(`https://config.inovarapi.com/apps/order/${credential}`).pipe(
            switchMap((response: any) => {

                // Store the config
                localStorage.setItem('cache:config', JSON.stringify(response));

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string, password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        // Get config for post data
        const config = JSON.parse(localStorage.getItem('cache:config'));

        return this._httpClient.post(`http://${config.ip}:${config.port}/inovarservice-order/public/sign-in`, credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.token;
                
                // Store salesman config
                localStorage.setItem('cache:salesman', JSON.stringify(response));
                // Set the authenticated flag to true
                this._authenticated = true;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(deleteMode: string = 'none'): Observable<any> {
        if (deleteMode === 'none') {
            return of(true);
        }

        // Remove the cache from the local storage
        localStorage.removeItem('cache:token');
        localStorage.removeItem('cache:cart');
        localStorage.removeItem('cache:table');
        localStorage.removeItem('cache:ticket');
        localStorage.removeItem('cache:salesman');
        localStorage.removeItem('cache:orders');

        if (deleteMode === 'all') {
            localStorage.removeItem('cache:config');
        }

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        return of(true);
    }
}
