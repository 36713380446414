import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LoadingService
{
    // Private
    private _status: boolean;
    private _text: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the private defaults
        this.status = false;
        this.text = '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for status
     */
    get status(): boolean
    {
        return this._status;
    }

    set status(status: boolean)
    {
        this._status = status;
    }

    /**
     * Getter for text
     */
    get text(): string
    {
        return this._text;
    }

    set text(text: string)
    {
        this._text = text;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    public show(text?: string)
    {
        this.text = text;
        this.status = true;
    }

    public hide()
    {
        this.status = false;
    }

    // Sync data
    public sync() {
        this.status = true;
    }
}
