import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {IonicModule} from '@ionic/angular';
import {EmptyLayoutComponent} from './empty.component';

@NgModule({
    declarations: [
        EmptyLayoutComponent
    ],
    imports: [
        RouterModule,
        SharedModule,
        IonicModule
    ],
    exports: [
        EmptyLayoutComponent
    ]
})
export class EmptyLayoutModule
{
}
