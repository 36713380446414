import { NgModule } from '@angular/core';
import { BeagleMediaWatcherService } from './media-watcher.service';

@NgModule({
    providers: [
        BeagleMediaWatcherService
    ]
})
export class BeagleMediaWatcherModule
{
    /**
     * Constructor
     *
     * @param {BeagleMediaWatcherService} _beagleMediaWatcherService
     */
    constructor(
        private _beagleMediaWatcherService: BeagleMediaWatcherService
    )
    {
    }
}
