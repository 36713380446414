import { ModuleWithProviders, NgModule } from '@angular/core';
import {BLEAGLE_APP_CONFIG} from './config.constants';
import {BeagleConfigService} from './config.service';

@NgModule()
export class BeagleConfigModule
{
    /**
     * Constructor
     *
     * @param {BeagleConfigService} _beagleConfigService
     */
    constructor(
        private _beagleConfigService: BeagleConfigService
    )
    {
    }

    /**
     * forRoot method for setting user configuration
     *
     * @param config
     */
    static forRoot(config: any): ModuleWithProviders<BeagleConfigModule>
    {
        return {
            ngModule : BeagleConfigModule,
            providers: [
                {
                    provide : BLEAGLE_APP_CONFIG,
                    useValue: config
                }
            ]
        };
    }
}
