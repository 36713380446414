import { NgModule } from '@angular/core';
import {BeagleSplashScreenService} from './splash-screen.service';

@NgModule({
    providers: [
        BeagleSplashScreenService
    ]
})
export class BeagleSplashScreenModule
{
    /**
     * Constructor
     *
     * @param {BeagleSplashScreenService} _beagleSplashScreenService
     */
    constructor(
        private _beagleSplashScreenService: BeagleSplashScreenService
    )
    {
    }
}
