import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, retryWhen, tap, timeout } from 'rxjs/operators';
import { Order } from './order.types';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    // Private
    private _orders: BehaviorSubject<Order[] | null>;
    private _order: BehaviorSubject<Order | null>;

    private readonly _config: any;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) {
        // Set the private defaults
        this._orders = new BehaviorSubject(null);
        this._order = new BehaviorSubject(null);

        this._config = JSON.parse(localStorage.getItem('cache:config'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get for products
     */
    get order$(): Observable<Order> {
        return this._order.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all orders
     */
    getOrders(): Observable<Order[]> {
        if (!this._config) {
            return;
        }

        return this._httpClient.get<Order[]>(`http://${this._config.ip}:${this._config.port}/inovarservice-order/public/order?mode=${this._config.mode}&app_mode=${this._config.app_mode}`).pipe(
            tap((orders: Order[]) => {
                // Update orders
                this._orders.next(orders);

                return orders;
            })
        );
    }

    /**
     * Get order
     *
     * @param code
     */
    getOrder(code: string): Observable<Order> {
        if (!this._config) {
            return;
        }

        return this._httpClient.get<Order>(`http://${this._config.ip}:${this._config.port}/inovarservice-order/public/order/${code}}?app_mode=${this._config.app_mode}`).pipe(
            tap((order: Order) => {
                // Update order
                this._order.next(order);
            })
        );
    }

    /**
     * Get order by qrcode
     *
     * @param hash
     */
    getOrderByQrcode(hash: string): Observable<Order> {
        if (!this._config) {
            return;
        }

        return this._httpClient.get<Order>(`http://${this._config.ip}:${this._config.port}/inovarservice-order/public/order/${hash}/qrcode`).pipe(
            tap((order: Order) => {
                // Update order
                this._order.next(order);

                return order;
            })
        );
    }

    /**
     * Create order
     *
     * @param body
     */
    createOrder(body: any): Observable<Order | any> {
        if (!this._config) {
            return;
        }

        return this._httpClient.post<Order>(`http://${this._config.ip}:${this._config.port}/inovarservice-order/public/order`, body)
            .pipe(
                tap((order: Order) => {
                    return order;
                })
            );
    }

    /**
     * Print order
     *
     * @param body
     */
    printOrder(body: any): Observable<Order> {

        if (!this._config) {
            return;
        }
        body.app_mode = this._config.app_mode;

        return this._httpClient.post<Order>(`http://${this._config.ip}:${this._config.port}/inovarservice-order/public/print/order`, body).pipe(
            tap((order: Order) => {
                return order;
            })
        );
    }

    transferOrder(body: any): Observable<Order> {
        if (!this._config) return;
        body.app_mode = this._config.app_mode;

        return this._httpClient.post<Order>(`http://${this._config.ip}:${this._config.port}/inovarservice-order/public/transfer`, body).pipe(
            tap((order: Order) => {
                return order;
            })
        );
    }
}
