import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CurrencyFormat} from '../core/pipes/currency';
import { FilterPipe } from '../core/pipes/filter.pipe';
import { CustomDatePipe } from '../core/pipes/customDate';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        CurrencyFormat,
        FilterPipe, 
        CustomDatePipe,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CurrencyFormat,
        FilterPipe,
        CustomDatePipe,
    ],
    providers: [
        DatePipe,
    ]
})
export class SharedModule
{
}
