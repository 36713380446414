import {ChangeDetectorRef, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from './auth.service';
import {BeagleSplashScreenService} from '../../../@beagle/services/splash-screen';
import {BeagleErrorScreenService} from '../../../@beagle/services/error-screen';
import {environment} from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    /**
     * Constructor
     *
     * @param {AuthService} _authService
     * @param {BeagleSplashScreenService} _splashScreen
     * @param {BeagleErrorScreenService} _errorScreen
     */
    constructor(
        private _authService: AuthService,
        private _splashScreen: BeagleSplashScreenService,
        private _errorScreen: BeagleErrorScreenService,
        private _router: Router
    ) {
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if (this._authService.accessToken) {
            newReq = req.clone({
                headers: req.headers.set('Token', this._authService.accessToken)
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {

                // Disable splash screen
                this._splashScreen.hide();

                if (error instanceof HttpErrorResponse && error.status === 0) {
                    this._authService.signOut('basic')
                    .subscribe(() => {
                        this._authService.toggleNetworkError(true);
                        this._router.navigate(['/sign-in']).then(() => {
                            this._errorScreen.show('Não foi possível conectar o servidor, verifique sua conexão');
                            this._errorScreen.deleteMode = 'none';
                            throw new Error('Não foi possível conectar o servidor, verifique sua conexão');
                            
                        });
                    });
                }

                if (error instanceof HttpErrorResponse && error.status === 500 ) {
                    this._errorScreen.show('Erro interno');
                    throw new Error(error?.message ?? 'Erro interno');
                }

                if (error instanceof HttpErrorResponse && error.status === 401) {
                    this._errorScreen.show('Você não têm permissão para essa ação');
                    this._errorScreen.deleteMode = 'basic';
                    throw new Error(error?.message ?? 'Você não têm permissão para essa ação');
                }

                if (error instanceof HttpErrorResponse && error.status === 404) {
                    throw new Error(error?.message ?? 'Não foi possível encontrar o recurso solicitado');
                }

                return throwError(error);
            })
        );
    }
}
