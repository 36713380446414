<!-- Container -->
<div class="container">

    <div class="wrapper">

        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
              it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>

    </div>

</div>
