import {NgModule, Optional, SkipSelf} from '@angular/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {BeagleMediaWatcherModule} from './services/media-watcher';
import {BeagleSplashScreenModule} from './services/splash-screen';
import {BeagleErrorScreenModule} from './services/error-screen';

@NgModule({
    imports: [
        BeagleMediaWatcherModule,
        BeagleSplashScreenModule,
        BeagleErrorScreenModule,
    ],
    providers: [
        {
            // Use the 'fill' appearance on form fields by default
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'fill'
            }
        }
    ]
})
export class BeagleModule {
    /**
     * Constructor
     *
     * @param parentModule
     */
    constructor(
        @Optional() @SkipSelf() parentModule?: BeagleModule
    ) {
        if (parentModule) {
            throw new Error('BeagleModule has already been loaded. Import this module in the AppModule only!');
        }
    }
}
