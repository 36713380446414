<ul class="mobile pb-4">
    <li>
        <a [routerLink]="'/'">
            <mat-icon svgIcon="heroicons_outline:home" [ngStyle]="design?.text"></mat-icon>
        </a>
    </li>
    <li *ngIf="config && config?.enable_ticket">
        <a [routerLink]="'/ticket'">
            <mat-icon svgIcon="heroicons_outline:ticket" [ngStyle]="design?.text"></mat-icon>
        </a>
    </li>
    <li *ngIf="config && !config?.enable_ticket">
        <a href="javascript:void(0)" (click)="new()">
            <mat-icon svgIcon="heroicons_outline:menu-alt-1" [ngStyle]="design?.text"></mat-icon>
        </a>
    </li>
    <li>
        <a href="javascript:void(0)" (click)="new()" class="center" [ngStyle]="design?.buttons?.primary?.style">
            <mat-icon svgIcon="heroicons_outline:plus" [ngStyle]="design?.buttons?.primary?.style"></mat-icon>
        </a>
    </li>
    <li>
        <a [routerLink]="'/cart'">
            <mat-icon svgIcon="heroicons_outline:shopping-cart" [ngStyle]="design?.text"></mat-icon>
        </a>
    </li>
    <li>
        <a href="javascript:void(0)" (click)="ticket()">
            <mat-icon svgIcon="heroicons_outline:qrcode" [ngStyle]="design?.text"></mat-icon>
        </a>
    </li>
</ul>

<ul class="desktop">
    <li>
        <a [routerLink]="'/'" class="home" [ngStyle]="design?.buttons?.primary?.style">
            <mat-icon svgIcon="heroicons_outline:home" [ngStyle]="design?.buttons?.primary?.style"></mat-icon>
        </a>
    </li>
    <li *ngIf="config && config.enable_ticket">
        <a [routerLink]="'/ticket'" class="enable" [ngStyle]="design?.buttons?.primary?.style">
            <span class="avatar"><mat-icon svgIcon="heroicons_outline:ticket" [ngStyle]="design?.buttons?.primary?.style"></mat-icon></span>
        </a>
    </li>
    <li *ngIf="config && !config.enable_ticket">
        <a href="javascript:void(0)" (click)="new()" class="category" [ngStyle]="design?.buttons?.primary?.style">
            <mat-icon svgIcon="heroicons_outline:menu-alt-1" [ngStyle]="design?.buttons?.primary?.style"></mat-icon>
        </a>
    </li>
    <li>
        <a [routerLink]="'/cart'" class="cart-menu" [ngStyle]="design?.buttons?.primary?.style">
            <mat-icon svgIcon="heroicons_outline:shopping-cart" [ngStyle]="design?.buttons?.primary?.style"></mat-icon>
        </a>
    </li>
    <li>
        <a href="javascript:void(0)" (click)="new()" class="new" [ngStyle]="design?.buttons?.primary?.style">
            <mat-icon svgIcon="heroicons_outline:plus" [ngStyle]="design?.buttons?.primary?.style"></mat-icon>
        </a>
    </li>
</ul>

<div class="popup" [ngClass]="{'ready': orderPopUp}">
    <div class="overlay" (click)="orderPopUp = false"></div>
    <div class="content">
        <a href="javascript:void(0)" (click)="orderPopUp = false" class="close"><mat-icon svgIcon="mat_outline:close"></mat-icon></a>

        <p class="pt-2 pb-8 fs-15">Comanda não encontrada</p>

        <button class="button btn-primary" (click)="orderPopUp = false" [ngStyle]="design?.buttons?.primary?.style" mat-flat-button [color]="'primary'">
            <span>Ok</span>
        </button>
    </div>
</div>
