import {NgModule} from '@angular/core';
import {LayoutComponent} from './layout.component';
import {SharedModule} from '../shared/shared.module';
import {EmptyLayoutModule} from './layouts/empty/empty.module';
import {MoonlightLayoutModule} from './layouts/moonlight/moonlight.module';
import {LoadingComponent} from './common/loading/loading.component';
import {MenuComponent} from './common/menu/menu.component';
import {RouterModule} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

const modules = [
    EmptyLayoutModule,
    MoonlightLayoutModule
];

@NgModule({
    declarations: [
        LayoutComponent,
        LoadingComponent,
        MenuComponent
    ],
    imports: [
        SharedModule,
        ...modules,
        RouterModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        ...modules,
        LoadingComponent,
        MenuComponent
    ]
})
export class LayoutModule
{
}
