import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VersionServiceService {

   // Private
   private _versionService: BehaviorSubject<any | null>;

   /**
    * Constructor
    *
    * @param {HttpClient} _httpClient
    */
   constructor(
       private _httpClient: HttpClient,
   )
   {
       // Set the private defaults
       this._versionService = new BehaviorSubject(null);
   }

   // -----------------------------------------------------------------------------------------------------
   // @ Accessors
   // -----------------------------------------------------------------------------------------------------

   /**
    * Get for version service backend
    */
   get versionService$(): Observable<any>
   {
       return this._versionService.asObservable();
   }

   // -----------------------------------------------------------------------------------------------------
   // @ Public methods
   // -----------------------------------------------------------------------------------------------------

   /**
    * Get version service
    */
   getVersion(): Observable<any>
   {
      const config = JSON.parse(localStorage.getItem('cache:config'));
      if (!config) {
          return;
      }

      return this._httpClient.get<any>(`http://${config.ip}:${config.port}/inovarservice-order/public/version`).pipe(
        tap((response: any) => {
          this._versionService.next(response);
          return response;
        })
      );
   }
}
