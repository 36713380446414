import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import * as Sentry from "@sentry/angular";
import { environment } from 'src/environments/environment';
import { VersionServiceService } from './version-service.service';
import { appInfo } from 'src/version';
@Injectable({
    providedIn: 'root'
})
export class SentryService
{
    config: any;
    salesman: any;
    version: string;
    versionService: string;
    // Private
    private _unsubscribeAll: Subject<any>;


    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _versionService: VersionServiceService
    ) {
        this._unsubscribeAll = new Subject();
        this.config = JSON.parse(localStorage.getItem('cache:config'));
        this.salesman = JSON.parse(localStorage.getItem('cache:salesman'));
        this.version = appInfo.appVersion;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sync data
     */
    setScopeSentry(){
        this._versionService.versionService$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response: any) => {
            Sentry.configureScope((scope) => { 
                scope.setExtras({
                    'ambiente': environment.production ? 'prod' : 'dev',
                    'colaborador': this.salesman?.salesman_name ?? 'Não encontrado',
                    'ip/porta': this.config?.ip + ':' + this.config?.port,
                    'identificador': this.config?.identity,
                    'versão-aplicativo': this.version,
                    'versão-serviço': response?.service_version ?? 'Não encontrada',
                });
            });
        });
    }

}
