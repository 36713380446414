import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../shared/shared.module';
import {IonicModule} from '@ionic/angular';
import {MoonlightLayoutComponent} from './moonlight.component';

@NgModule({
    declarations: [
        MoonlightLayoutComponent
    ],
    imports: [
        RouterModule,
        SharedModule,
        IonicModule
    ],
    exports: [
        MoonlightLayoutComponent
    ]
})
export class MoonlightLayoutModule
{
}
