import {ErrorHandler, Injectable, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ExtraOptions, PreloadAllModules, Router, RouteReuseStrategy, RouterModule, Scroll} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {appRoutes} from './app.routing';
import {BeagleModule} from '../@beagle';
import {BeagleConfigModule} from '../@beagle/services/config';
import {appConfig} from './core/config/app.config';
import {LayoutModule} from './layout/layout.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {IonicStorageModule} from '@ionic/storage-angular';
import {Network} from '@ionic-native/network/ngx';
import {Drivers, Storage} from '@ionic/storage';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {NgxQRCodeModule} from 'ngx-qrcode2';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import * as Sentry from "@sentry/angular";
import { environment } from 'src/environments/environment';

Sentry.init({
    dsn: "https://04540b859b3343aeaa9f464ece082e0e@o1162377.ingest.sentry.io/6249552",
    environment: environment.production ? 'prod' : 'dev'
});

@Injectable()
class SentryErrorHandler implements ErrorHandler { 
    handleError(error) {
        Sentry.captureException(error?.originalError || error);
    }
 }

export function getErrorHandler(): ErrorHandler {
    return environment.production ? new SentryErrorHandler() : new ErrorHandler();
}

const routerConfig: ExtraOptions = {
    scrollPositionRestoration: 'top',
    preloadingStrategy       : PreloadAllModules,
    relativeLinkResolution   : 'legacy',
    scrollOffset: [0,0]
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Ionic
        IonicStorageModule.forRoot({
            name: '_orderDB',
            driverOrder: [Drivers.IndexedDB]
        }),
        IonicModule.forRoot(),

        // Beagle
        BeagleModule,
        BeagleConfigModule.forRoot(appConfig),

        // Core
        CoreModule,

        // Layout
        LayoutModule,

        // Qrcode
        NgxQRCodeModule,
        MatButtonModule,
        MatIconModule
    ],
    providers: [
        Network,
        Storage,
        StatusBar,
        {
            provide: ErrorHandler,
            useFactory: getErrorHandler
        }
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule]
})
export class AppModule {
}
