import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SentryService } from './core/services/sentry.service';
import { VersionServiceService } from './core/services/version-service.service';
@Injectable({
    providedIn: 'root'
})
export class VersionResolver implements Resolve<any> {

    /**
     * Constructor
     *
     * @param {VersionService} _versionService
     */
    constructor(
        private _versionService: VersionServiceService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver version service backend
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this._versionService.getVersion()?.subscribe();
    }
}

@Injectable({
    providedIn: 'root'
})
export class LoadScopeSentryResolver implements Resolve<any> {

    /**
     * Constructor
     *
     * @param {SentryService} _sentryService
     */
    constructor(
        private _sentryService: SentryService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver version service backend
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this._sentryService.setScopeSentry(); 
    }
}
