import {Route} from '@angular/router';
import {LayoutComponent} from './layout/layout.component';
import {NoAuthGuard} from './core/auth/guards/noAuth.guard';
import {AuthGuard} from './core/auth/guards/auth.guard';
import { LoadScopeSentryResolver, VersionResolver } from './app.resolver';

export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/finance'
    {
        path: '',
        pathMatch : 'prefix',
        redirectTo: 'home',
    },

    // Auth routes (guest)
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        resolve: {
            version: VersionResolver,
            sentryScope: LoadScopeSentryResolver
        },
        children: [
            {path: 'sign-in', loadChildren: () => import('./modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'moonlight'
        },
        resolve: {
            version: VersionResolver,
            sentryScope: LoadScopeSentryResolver
        },
        children: [
            {path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)},
            {path: 'product', loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule)},
            {path: 'cart', loadChildren: () => import('./modules/cart/cart.module').then(m => m.CartModule)},
            {path: 'order', loadChildren: () => import('./modules/order/order.module').then(m => m.OrderModule)},
            {path: 'category', loadChildren: () => import('./modules/category/category.module').then(m => m.CategoryModule)},
            {path: 'ticket', loadChildren: () => import('./modules/ticket/ticket.module').then(m => m.TicketModule)},
        ]
    },

];
