import {Component} from '@angular/core';
import {AuthService} from './core/auth/auth.service';
import {BeagleErrorScreenService} from '../@beagle/services/error-screen';
import { LoadingService } from './layout/common/loading/loading.service';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent
{
    constructor(
        public _errorService: BeagleErrorScreenService,
        private _loadingService: LoadingService,
        private _authService: AuthService,
    ){}

    /**
     * Log out app
     */
    logout(): void
    {
        // Sign out
        this._errorService.hide();

        this._authService.signOut(this._errorService.deleteMode)
     

    }

    reload(): void {
        window.location.reload();
    }


}
