import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class BeagleErrorScreenService
{
    message: string;
    deleteMode: string;

    /**
     * Constructor
     *
     * @param {DOCUMENT} _document
     * @param {Router} _router
     */
    constructor(
        @Inject(DOCUMENT) private _document: any,
        private _router: Router
    )
    {
        // Initialize
        this._init();
        this.message = '';
        this.deleteMode = 'none';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Initialize
     *
     * @private
     */
    private _init(): void
    {
        // Hide it on the first NavigationEnd event
        this._router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                take(1)
            )
            .subscribe(() => {

                // Hide the splash screen
                this.hide();
            });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Show the error screen
     */
    show(text: string = ''): void
    {
        this.message = text;
    }

    /**
     * Hide the splash screen
     */
    hide(): void
    {
        this.message = '';
    }
}
