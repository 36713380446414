<ion-app>
    <div class="popup" [ngClass]="{'ready': _errorService.message}">
        <div class="overlay"></div>
        <div class="content flex flex-col">
            <a href="javascript:void(0)" (click)="logout()" class="close">
                <mat-icon svgIcon="mat_outline:close"></mat-icon>
            </a>
            <p class="pt-2 pb-8 fs-15">{{ _errorService.message }}</p>
            <div class="flex flex-col justify-between align-center">
                <button class="button btn-primary w-full" (click)="reload()" mat-flat-button [color]="'primary'">
                    <span>Tentar novamente</span>
                </button>
            </div>
        </div>
    </div>
    <ion-router-outlet></ion-router-outlet>
</ion-app>


