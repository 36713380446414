import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'filterSearch',
})

export class FilterPipe implements PipeTransform {

    /**
     * Transform the array into a new array with the search term
     * @param items 
     * @param keys 
     * @param searchTerm 
     * @returns 
     */
    transform(items: any[], keys: any[], searchTerm: any): any[] {
        let filteredList = [];

        if (searchTerm && Array.isArray(items) && Array.isArray(keys) && keys.length > 0) {

            let newSearchTerm = !isNaN(searchTerm) ? searchTerm?.toString() : searchTerm?.toString()?.toUpperCase();
            let propInItem;

            items?.filter(item => {
                keys.forEach(key => {
                    propInItem = isNaN(item[key]) ? item[key]?.toString()?.toUpperCase() : item[key]?.toString();

                    if (propInItem.indexOf(newSearchTerm) !== -1) 
                        filteredList.push(item);
                });
            });

            return filteredList;
        }

        return items;
    }
}